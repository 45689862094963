import React from "react";


const brandlogoData = [
  {
    id: 1,
    image: "images/an-naba.svg",
  },
  {
    id: 2,
    image: "images/bri-logo.svg",
  },
  {
    id: 3,
    image: "images/datacomm.png",
  },
];

function Brandlogos() {
  return (
    // <div className="container">
    // <Pagetitle title="Recent Works" />
    <div id="branglogos">
      <div className="container">
        <div className="row">
          {brandlogoData.map((brandlogo) => (
            <div className="col-md-4 col-6" key={brandlogo.id}>
              <div className="client-item">
                <div className="inner">
                  <img src={brandlogo.image} alt="client-name" />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
    // </div>
  );
}

export default Brandlogos;
