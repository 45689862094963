import React from "react";
import { Link } from "react-scroll";
import Pagetitle from "../elements/Pagetitle";
import Service from "../elements/Service";

const servicesData = [
  {
    id: 1,
    icon: "/images/thin-client.svg",
    title: "Computer Network",
    content:
      "Perform LAN, WAN, and routing configuration on network for interconnection needs between network nodes. Network devices that I usually hold are Mikrotik and Cisco",
    color: "#6C6CE5",
    contentColor: "light",
  },
  {
    id: 2,
    icon: "images/web-computer.svg",
    title: "Virtualization & Cloud",
    content:
      "Perform design and implementation network infrastructure based cloud/virtual environment. The cloud providers that I usually use to build infrastructure are AWS and IDCloudhost. I also have experience in using virtual environments such as VMWare ESXi & Proxmox.",
    color: "#F9D74C",
    contentColor: "dark",
  },
  {
    id: 3,
    icon: "images/code.svg",
    title: "Scripting",
    content:
      "Automate processes infrastructure orchestration using script. The scripting language that I usually use is Bash and Nodejs",
    color: "#F97B8B",
    contentColor: "light",
  },
];

function Services() {
  return (
    <section id="services">
      <div className="container">
        <Pagetitle title="Skills" />
        <div className="row fix-spacing">
          {servicesData.map((service) => (
            <div className="col-md-4" key={service.id}>
              <Service service={service} />
            </div>
          ))}
        </div>
        <div className="mt-5 text-center">
          <p className="mb-0">
            Looking for a custom job?{" "}
            <Link
              className="colorpink pointer"
              to="section-contact"
              spy={true}
              smooth={true}
              duration={500}
            >
              Click here
            </Link>{""}
            to contact me! 👋
          </p>
        </div>
      </div>
    </section>
  );
}

export default Services;
