import React from "react";
import Slider from "react-slick";
import ScrollAnimation from "react-animate-on-scroll";
import Pagetitle from "../elements/Pagetitle";
import Testimonial from "../elements/Testimonial";

const sliderData = [
  {
    id: 1,
    avatar: "images/certificates/aws-certified.png",
    userName: "AWS Certified Solution Architect Associate",
    subtitle: 'Issued by AWS',
    // review:
    //   "I enjoy working with the theme and learn so much. You guys make the process fun and interesting. Good luck! 👍",
  },
  {
    id: 2,
    avatar: "images/certificates/aws-academy.png",
    userName: "AWS Academy Certified",
    subtitle: "Issued by AWS",
    // review:
    //   "I enjoy working with the theme and learn so much. You guys make the process fun and interesting. Good luck! 👍",
  },
  {
    id: 3,
    avatar: "images/certificates/ccna1.png",
    userName: "CCNA Academy Certified - ENSA",
    subtitle: "Issued by Cisco",
    // review:
    //   "I enjoy working with the theme and learn so much. You guys make the process fun and interesting. Good luck! 👍",
  },
  {
    id: 4,
    avatar: "images/certificates/ccna2.png",
    userName: "CCNA Academy Certified - SRWE",
    subtitle: "Issued by Cisco",
    // review:
    //   "I enjoy working with the theme and learn so much. You guys make the process fun and interesting. Good luck! 👍",
  },
  {
    id: 5,
    avatar: "images/certificates/ccna3.png",
    userName: "CCNA Academy Certified - Introduction to Network",
    subtitle: "Issued by Cisco",
    // review:
    //   "I enjoy working with the theme and learn so much. You guys make the process fun and interesting. Good luck! 👍",
  },
  {
    id: 6,
    avatar: "images/certificates/mtcna.jpg",
    userName: "Mikrotik Certified Network Associate",
    subtitle: "Issued by Mikrotik",
    // review:
    //   "I enjoy working with the theme and learn so much. You guys make the process fun and interesting. Good luck! 👍",
  },
  {
    id: 7,
    avatar: "images/certificates/nse1.png",
    userName: "Network Security Associate 1",
    subtitle: "Issued by Fortinet",
    // review:
    //   "I enjoy working with the theme and learn so much. You guys make the process fun and interesting. Good luck! 👍",
  },
  {
    id: 8,
    avatar: "images/certificates/nse2.png",
    userName: "Network Security Associate 2",
    subtitle: "Issued by Fortinet",
    // review:
    //   "I enjoy working with the theme and learn so much. You guys make the process fun and interesting. Good luck! 👍",
  },
  {
    id: 9,
    avatar: "images/certificates/nse3.png",
    userName: "Network Security Associate 3",
    subtitle: "Issued by Fortinet",
    // review:
    //   "I enjoy working with the theme and learn so much. You guys make the process fun and interesting. Good luck! 👍",
  },
];

function Testimonials() {
  const slidetSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <section id="testimonials">
      <div className="container">
        <Pagetitle title="My Certificates" />
        <Slider {...slidetSettings} className="testimonials-wrapper">
          {sliderData.map((slider) => (
            <Testimonial key={slider.id} slider={slider} />
          ))}
        </Slider>
      </div>
    </section>
  );
}

export default Testimonials;
