import React from "react";
import Pagetitle from "../elements/Pagetitle";
import Timeline from "../elements/Timeline";

const educationData = [
  {
    id: 1,
    title: "Bachelor's Degree",
    years: "2020 - 2022",
    company: "Mercubuana University",
    content:
      "I have graduated from a bachelor's degree at Mercubuana University in 2022 with an educational background in electrical engineering with a focus on Telecommunication Engineering.",
  },
  {
    id: 2,
    title: "Diploma’s Degree",
    years: "2016 - 2019",
    company: "Telkom University Jakarta",
    content:
      "I have graduated from a Diploma's degree at Telkom University Jakarta in 2019 with an educational background in Telecommunication engineering.",
  },
];

const experienceData = [
  {
    id: 1,
    title: "Network Operation Center Operator",
    years: "01/2020 - Present",
    company: "PT. Satkomindo Mediyasa",
    content:
      "My responsibility in this position is to monitor connection status and network infrastructure devices at PT. BRI Tbk. and perform troubleshooting when problems occur on the network.",
  },
  {
    id: 2,
    title: "Web Developer",
    years: "12/2019 - 02/2020",
    company: "PT. An-naba International",
    content:
      "My responsibility in this position is to solve Hajj travel booking proses into web-based application and perform a presentation to the client in using the application.",
  },
  {
    id: 3,
    title: "Field Technician",
    years: "10/2019 - 12/2019",
    company: "PT. Datacomm Diangraha",
    content:
      "My responsibility in this position is to perform several surveys to BTS site in JABODETABEK area and implemented Metro-E devices for the needs of the Catapult Opticalization project from ISP Indosat Ooredoo.",
  },
];

function Experiences() {
  return (
    <section id="experience">
      <div className="container">
        <Pagetitle title="Experience" />
        <div className="row">
          <div className="col-md-6">
            <div className="timeline edu bg-white rounded shadow-dark padding-30 overflow-hidden">
              {educationData.map((education) => (
                <Timeline key={education.id} education={education} />
              ))}
              <span className="line"></span>
            </div>
          </div>

          <div className="col-md-6">
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
            <div className="timeline exp bg-white rounded shadow-dark padding-30 overflow-hidden">
              {experienceData.map((experience) => (
                <Timeline key={experience.id} education={experience} />
              ))}
              <span className="line"></span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Experiences;
